// Info messages
export const PASSWORD_POLICY = 'Your password must have at least 8 characters including one uppercase letter, one lowercase letter, one number and a special character.'

// Success messages
export const ADD_USER_SUCCESS = "User successfully added!";
export const INVITE_USER_SUCCESS = "User successfully invited!";
export const INVITES_SENT_SUCCESS = "Successfully sent invites!";
export const CHANGE_USER_PERMISSION_SUCCESS = "User permission successfully changed!";
export const BOOK_A_CALL_SUCCESS = "Call booked successfully. Our team will contact you soon.";
export const PUBLISH_BROADCAST_SUCCESS = "Your broadcast has been published!";

// Error messages
export const PASSWORDS_DO_NOT_MATCH_ERROR = 'Passwords do not match.';
export const INVALID_EMAIL_ADDRESS_ERROR = 'Please enter a valid email address.';
export const INVALID_URL_ERROR = 'Please enter a valid URL including the protocol(http://, https:// etc.)';
export const INVALID_IMAGE_ERROR = 'Unsupported image type.';
export const INCORRECT_CONFIRMATION_CODE_ERROR = 'Confirmation code is incorrect.';
export const UNEXPECTED_ERROR = "An unexpected error occurred.";
export const EMAIL_NOT_FOUND_ERROR = "User with that email not found.";
export const USER_NOT_ALLOWED_FOR_GIVEN_PERMISSIONS_ERROR = "User cannot be given that permission.";
export const INVALID_FULL_NAME_ERROR = 'First name and last name cannot be empty.';
export const INVALID_ORG_NAME_ERROR = 'Organization name cannot be empty.';
export const ACCESS_DENIED_ERROR = "Access denied.";
export const USER_DOES_NOT_EXIST_ERROR = "User does not exist.";
export const UPLOAD_AVATAR_ENETER_EMAIL_FIRST_ERROR = "Please enter a valid email before uploading avatar.";
export const MAX_FILE_SIZE_EXCEEDED_ERROR = "Documents must be less than 5 MB. Please contact Senso to add this file to your organization.";
export const INVALID_FILE_TYPE_ERROR = "Invalid file type. Please upload a PDF file.";
export const MULTI_SOURCE_VIEWER_HAS_NO_DOCUMENTS_ERROR = 'No documents to show.';
export const QUERY_HAS_NO_DOCUMENTS_ERROR = 'Query did not have any documents. Please select another query.';
export const BROADCAST_MSG_EMPTY_ERROR = 'Broadcast message cannot be empty.';

// AWS Error messages - DO NOT CHANGE. These are actual error messages coming from aws
export const AWS_INVALID_VERIFICATION_CODE_ERROR = 'Invalid verification code provided, please try again.';
export const AWS_ATTEMPT_LIMIT_EXCEED_ERROR = 'Attempt limit exceeded, please try after some time.'; 