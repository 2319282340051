import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash';

interface UploadingFile {
    fileName: string;
    documentId: string;
    status: 'uploading' | 'processing';
}

interface FetchKnowledgeStateI {
    isUploadModalOpen: boolean;
    isContentMenuOpen: boolean;
    isAddNewCategoryBtnClicked: boolean;
    contentCreating: { document_id: string; title: string } | undefined;
    contentEditing: { document_id: string; title: string } | undefined;
    uploadingFiles: UploadingFile[];
};

const initialState: FetchKnowledgeStateI = {
    isUploadModalOpen: false,
    isContentMenuOpen: false,
    isAddNewCategoryBtnClicked: false,
    contentCreating: undefined,
    contentEditing: undefined,
    uploadingFiles: [],
};

export const fetchKnowledgeSlice = createSlice({
    name: 'fetch-knowledge',
    initialState,
    reducers: {
        setIsUploadModalOpen: (state, action: PayloadAction<boolean>) => {
            state.isUploadModalOpen = action.payload;
        },
        setIsContentMenuOpen: (state, action: PayloadAction<boolean>) => {
            state.isContentMenuOpen = action.payload;
        },
        setIsAddNewCategoryBtnClicked: (state, action: PayloadAction<boolean>) => {
            state.isAddNewCategoryBtnClicked = action.payload;
        },
        setContentCreating: (state, action: PayloadAction<{ document_id: string, title: string } | undefined>) => {
            state.contentCreating = action.payload;
        },
        setContentEditing: (state, action: PayloadAction<{ document_id: string, title: string } | undefined>) => {
            state.contentEditing = action.payload;
        },
        clearFetchKnowledgeState: (state) => {
            state.isUploadModalOpen = false;
            state.isContentMenuOpen = false;
            state.isAddNewCategoryBtnClicked = false;
            state.contentCreating = undefined;
            state.contentEditing = undefined;
            state.uploadingFiles = [];
        },
        addUploadingFile: (state, action: PayloadAction<UploadingFile>) => {
            const fileExists = state.uploadingFiles.find((file) =>
                file.documentId === action.payload.documentId
            )
            if (!fileExists) {
                state.uploadingFiles = [action.payload, ...state.uploadingFiles]
            } else {
                if (fileExists.status !== action.payload.status) {
                    updateUploadingFile({ documentId: action.payload.documentId, status: action.payload.status });
                }
            }
        },
        updateUploadingFile: (state, action: PayloadAction<{ documentId: string; status: 'uploading' | 'processing'; newDocumentId?: string }>) => {
            const clonedUploadingFiles = cloneDeep(state.uploadingFiles);
            const indexOfFile = clonedUploadingFiles.findIndex(file => file.documentId === action.payload.documentId);
            if (indexOfFile !== -1) {
                if (action.payload.newDocumentId) {
                    clonedUploadingFiles[indexOfFile].documentId = action.payload.newDocumentId
                }
                clonedUploadingFiles[indexOfFile].status = action.payload.status;
                state.uploadingFiles = clonedUploadingFiles;
            }
        },
        removeUploadingFile: (state, action: PayloadAction<{ documentId: string }>) => {
            state.uploadingFiles = state.uploadingFiles.filter(file => file.documentId !== action.payload.documentId);
        },
    },
})

export const {
    setIsUploadModalOpen,
    setIsContentMenuOpen,
    setIsAddNewCategoryBtnClicked,
    setContentCreating,
    setContentEditing,
    clearFetchKnowledgeState,
    addUploadingFile,
    updateUploadingFile,
    removeUploadingFile,
} = fetchKnowledgeSlice.actions;

export default fetchKnowledgeSlice.reducer;